import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Container, Row, Col } from 'react-bootstrap';
import UserForm from './UserForm';
import Login from './Login';
import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";
import axios from 'axios';
import MyNavbar from './MyNavbar';
import Logout from './Logout';
import Signup from './Signup';

const checkAuthStatus = () => {
  const token = localStorage.getItem('jwt_token');

  console.log('checkAuthStatus()')


  if (!!token) 
    return true
  else
    return false
};


function App() {
  const [logged, setLogged] = useState(checkAuthStatus);
  
  // useEffect(() => {

  //   checkAuthStatus();
  // });

  console.log(logged)

  return (
    <>
      <MyNavbar></MyNavbar>
      <Container>
      <Row>
        <Col>
        <Routes>
          <Route path="/">
            <Route index element={<div>Home</div>} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={ <Logout /> } />
            <Route path="signup" element={ <Signup /> } />
            <Route path="report" element={ logged ? <UserForm /> : <Navigate to="/login" /> } />
            <Route path="*" element={ <Navigate to="/" /> } />
          </Route>
        </Routes>
        </Col>
      </Row>
    </Container>
  </>
  );
}

export default App;