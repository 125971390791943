import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const formData = new URLSearchParams();
      formData.append('username', email);
      formData.append('password', password);
        
      const response = await axios.post('https://trener.zarabiamboumiem.pl:8000/token', formData);

      if (response.data && response.data.access_token) {
        localStorage.setItem('jwt_token', response.data.access_token);
        console.log('in Login before navigate')
        navigate('/report');    
        console.log('in Login after navigate')
        window.location.reload()      
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  };

  return (
    <>
            {error && (
            <Alert variant="danger" className='mt-5'>Nieprawidłowy login lub hasło</Alert>
            )}

            <div className="login-container">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                </Form.Group>

                <Form.Group controlId="password" className='mt-2'>
                <Form.Label>Hasło</Form.Label>
                <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                </Form.Group>

                <Button variant="primary" type="submit" className='mt-2'>
                Zaloguj się
                </Button>
            </Form>
            </div>
    </>
  );
};

export default Login;
