import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Col, Row, Image } from 'react-bootstrap';
import axios from 'axios';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useNavigate } from 'react-router-dom';


const token = localStorage.getItem('jwt_token');
console.log('::', token)

const api = axios.create({
    baseURL: 'https://trener.zarabiamboumiem.pl:8000/',
    headers: {
      'Authorization': `Bearer ${token}`
    }
    });

const defaultFormValues = {
    gender: '',
    birthYear: '',
    education: '',
    foreignLanguages: '',
    personalityCode: '',
    whetherCliftonStrengths: false,
    skills: '',
    workExperience: '',
    currentJob: '',
    jobExperience: '',
    currentSalary: '',
    jobDescription: '',
    valuedAspects: '',
    frustratingAspects: '',
    workTypePreference: '',
    travelPercentage: '',
    jobLimitations: '',
    dreamJob: ''
}

const UserForm = () => {
  const [formData, setFormData] = useState(defaultFormValues);
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [isSaved, setIsSaved] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    api.get('/get-report-data')
    .then(response => {
        console.log(response.data.data)
        if (response.data.data)
            setFormData(response.data.data);
        setLoading(false);
    })
    .catch(error => {
      console.error("Error fetching data: ", error);
    });
    
  }, [])

  useEffect(() => {

    let k: keyof typeof formData;
    let i=0;
    for (k in formData) {
      if (k!='whetherCliftonStrengths' && formData[k]!='')
        i++;
    }

    setProgress(i/17*100)

    const delayDebounceFn = setTimeout(() => {
        handleSave()
    }, 5000)
    
    return () => clearTimeout(delayDebounceFn)
    
  }, [formData])    


  const handleChange = (event:any) => {
    let { name, value} = event.target;

    if (event.target.name == 'whetherCliftonStrengths') {
        value = event.target.checked
    }

    setFormData(prevState => ({ ...prevState, [name]: value }));
    setIsSaved(false);
  };

  const handleSave = async () => {
    if (loading) return false;

    try {
      api.post('/save-report-data', { data: formData })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error("Error sending data: ", error);
      });

      setIsSaved(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event:any) => {
    event.preventDefault();
    try {
      // Send formData to your API endpoint
      // await axios.post('/api/submit', formData);

      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  return loading ? <div>Ładowanie formularza...</div> : (
    <>
        <Form id="raport" onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-1 mt-3">
            <Col sm={12}>
                <Image src="anna.png" rounded className='anna-foto' />
                <h1>Cześć!</h1>
                <p>Jestem Anna, Twoja <strong>osobista trenerka rozwoju</strong>, gotowa pokazać Ci drogę do realizacji Twojego pełnego potencjału zawodowego. Wszystko zaczyna się od tego formularza - Twojego klucza do unikalnej ścieżki kariery. Na podstawie informacji o Twoich mocnych stronach, doświadczeniu i oczekiwaniach, przygotuję dla Ciebie szczegółowy raport.</p>
                <p>Chociaż tylko niektóre pola formularza są <strong>obowiązkowe</strong>, serdecznie zachęcam Cię do wypełnienia jak najwięcej z nich. Im więcej o Tobie wiem, tym lepiej mogę dostosować strategię do Twojego profilu. Zacznij swoją podróż ku lepszej przyszłości zawodowej już dziś!</p>
                <p>Twój komfort jest dla mnie kluczowy - formularz automatycznie zapisuje Twoje odpowiedzi, umożliwiając Ci powrót do niego w dowolnym momencie. Po ostatecznym przesłaniu, nie będzie możliwości wprowadzania zmian, ale już <strong>w ciągu 24 godzin otrzymasz indywidualnie przygotowany raport</strong>, ułatwiający wybór odpowiedniej ścieżki kariery!</p>
            </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Płeć</Form.Label>
            <Col sm={5}>
            <Form.Select aria-describedby="genderHelpBlock" name='gender' value={formData.gender} onChange={handleChange} disabled={isSubmitted}>
                <option>---</option>
                <option value="k">Kobieta</option>
                <option value="m">Mężczyzna</option>
                <option value="i">Inna / Nie chcę odpowiadać</option>
            </Form.Select>
            </Col>
            <Col sm={5}>
            <Form.Text id="genderHelpBlock" muted>
                Dzięki temu będę wiedziała jak się do Ciebie zwracać.
            </Form.Text>     
            </Col>   
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Rok urodzenia</Form.Label>
            <Col sm={5}>
                <Form.Control
                type="number"
                name="birthYear"
                value={formData.birthYear}
                onChange={handleChange}
                disabled={isSubmitted}
                placeholder='w postaci 4-cyfrowej, np. 1995'
                minLength={4}
                maxLength={4}
                style={{ width: '280px' }}
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="education" className="mb-1">
            <Form.Label column sm={2}>
                <strong>Wykształcenie</strong>
            </Form.Label>
            <Col sm={5}>
                <Form.Control
                    as="textarea"
                    name="education"
                    value={formData.education}
                    onChange={handleChange}
                    disabled={isSubmitted}
                    required
                    aria-describedby="educationHelpBlock"
                    style={{ height: '200px' }}
                    />
            </Col>
            <Col sm={5}>
            <Form.Text id="educationHelpBlock" muted>
                Wykształcenie podaj w wygodnym dla Ciebie formacie, np.:<br />
                <em>
                    Politechnika Wrocławska, kierunek Elektronika, studia magisterskie, 2000-2005<br />
                    Szkoła Główna Menedżeska, studia MBA, 2005-2008
                </em>
            </Form.Text>     
            </Col>   
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}><strong>Języki obce</strong></Form.Label>
            <Col sm={5}>
                <Form.Control
                as="textarea"
                name="foreignLanguages"
                value={formData.foreignLanguages || "Język angielski\nczytanie - [0-5] \npisanie - [0-5]\nrozumienie ze słuchu - [0-5]\nmówienie/rozmowa - [0-5]\nuzyskane certyfikaty - "}
                onChange={handleChange}
                disabled={isSubmitted}
                style={{ height: '200px' }}
            />
            </Col>
            <Col sm={5}>
                <Form.Text id="foreignLanguages" muted>
                    Jakie znasz języki obce? <br /><br />                    
                    Wpisz każdy język jaki znasz - jeden pod drugim - w formacie:<br />
                    <em>
                        Język angielski<br />czytanie - [0-5] <br />pisanie - [0-5]<br />rozumienie ze słuchu - [0-5]<br />mówienie/rozmowa - [0-5]<br />uzyskane certyfikaty - 
                    </em>   
                </Form.Text>
            </Col>

        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Profil (CODE) z testu <a href='https://www.16personalities.com/pl/darmowy-test-osobowosci' target='_blank'>16 Personalities</a></Form.Label>
            <Col sm={5}>
                <Form.Control
                type="text"
                name="personalityCode"
                value={formData.personalityCode}
                onChange={handleChange}
                disabled={isSubmitted}
                aria-describedby="personalityCodeHelpBlock"
                maxLength={6}
                minLength={4}
                placeholder='np. ESTJ-A'
                style={{ width: '150px' }}
                />
            </Col>
            <Col sm={5}>
            <Form.Text id="personalityCodeHelpBlock" muted>
                Skorzystaj z bezpłatnego i łatwo dostępnego w języku polskim testu <a href='https://www.16personalities.com/pl/darmowy-test-osobowosci' target='_blank'>16 Personalities</a>. W ciągu zaledwie 12 minut, bez konieczności rejestracji, otrzymasz kod, np. INTP-A, opisujący Twój typ osobowości. Proszę wpisz ten kod w pole obok - choć nie jest to obowiązkowe, pozwoli mi to precyzyjniej dostosować rekomendacje do Twojego profilu.<br /><br />
                Jeżeli posiadasz już 4-literowy kod z innego testu badającego typ osobowości Carla Gustava Junga, możesz go wprowadzić. Pamiętaj jednak, że wyniki testów osobowości mogą ewoluować razem z nami. Dlatego warto poświęcić te 12 minut na jego ponowne przeprowadzenie, szczególne jeżeli od ostatniego badania minęło już sporo czasu.
            </Form.Text>     
            </Col>   

        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}><strong>Jakie są Twoje najważniejsze umiejętności?</strong></Form.Label>
            <Col sm={5}>
                <Form.Check
                type="switch"
                id="custom-switch"
                label="Zaznacz, jeżeli są to umiejętności Clifton Strengths"
                name="whetherCliftonStrengths"
                onChange={handleChange}
                checked = {formData.whetherCliftonStrengths}
                />

                <Form.Control
                as="textarea"
                name="skills"
                aria-describedby="skillsHelpBlock"
                value={formData.skills}
                onChange={handleChange}
                disabled={isSubmitted}
                style={{ height: '200px' }}
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="skillsHelpBlock" muted>
                    Test <a href='https://store.gallup.com/c/en-ie/1/cliftonstrengths' target='_blank'>Clifton Strengths</a> od Gallupa nie jest darmowy, a nawet można powiedzieć, że jest drogi, jednak wg mnie jest to obecnie najlepszy test określający nasze mocne strony i obszary do rozwoju. W <a href='https://store.gallup.com/p/en-ie/10108/top-5-cliftonstrengths' target='_blank'>wersji tańszej</a>, odkrywającej tylko 5 naszych najsilniejszych stron, kosztuje 105&nbsp;zł, a <a href='https://store.gallup.com/p/en-ie/10003/cliftonstrengths-34' target='_blank'>pełny raport</a>, z uporządkowanymi 34 "talentami" i dodatkowymi materiałami, to już wydatek 315&nbsp;zł. Nie mam z tego tytułu żadnych korzyści, ale szczerze polecam przynajmniej określenie tych najważniejszych 5 Clifton Strengths. Zakup możliwy jest tylko w języku angielskim, ale sam test i końcowy raport mogą już być po polsku.
                    <br /><br />
                    <strong>Jeżeli nie chcesz wydawać pieniędzy</strong>, samodzielnie opisz swoje mocne strony. Możesz też skorzystać z <a href='' target='_blank'>tego arkusza</a>, zawierającego listę wszystkich 34 Clifton Strengths - wraz z opisami.
                </Form.Text>
            </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Lata doświadczenia zawodowego</Form.Label>
            <Col sm={5}>
                <Form.Control
                type="text"
                name="workExperience"
                value={formData.workExperience}
                onChange={handleChange}
                disabled={isSubmitted}
                aria-describedby="workExperience"
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="workExperience" muted>
                    Od ilu lat pracujesz zawodowo? Np. <em>20 lat</em><br />Możesz też podać od którego roku pracujesz, np. <em>od 2012 roku</em>.
                </Form.Text>
            </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}><strong>Obecne stanowisko</strong></Form.Label>
            <Col sm={5}>
                <Form.Control
                type="text"
                name="currentJob"
                value={formData.currentJob}
                onChange={handleChange}
                disabled={isSubmitted}
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="currentJob" muted>
                    Możesz podać nazwę polską lub angielską. Jeżeli uważasz, że nazwa Twojego stanowiska z umowy nie oodaje rzeczywistego charakteru Twojej pracy, wpisz nazwę stanowiska, którą uznasz za odpowiednią.
                </Form.Text>
            </Col>
            
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}><strong>Lata doświadczenia na obecnym stanowisku</strong></Form.Label>
            <Col sm={5}>
                <Form.Control
                type="text"
                name="jobExperience"
                value={formData.jobExperience}
                onChange={handleChange}
                disabled={isSubmitted}
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Obecne zarobki</Form.Label>
            <Col sm={5}>
                <Form.Control
                type="text"
                name="currentSalary"
                value={formData.currentSalary}
                onChange={handleChange}
                disabled={isSubmitted}
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="currentSalary" muted>
                    Kwotę możesz podać w dowolnym formacie, ale zaznacz, czy jest to kwota netto - "na rękę", czy brutto. Jeżeli forma zatrudniena ma znaczenie, to też możesz ją podać. Proponując Ci opcje rozwoju postaram się wybrać tylko takie, które dadzą Ci szansę na większe zarobki.
                </Form.Text>
            </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}><strong>Obowiązki na obecnym stanowisku</strong></Form.Label>
            <Col sm={5}>
                <Form.Control
                as="textarea"
                name="jobDescription"
                value={formData.jobDescription}
                onChange={handleChange}
                disabled={isSubmitted}
                style={{ height: '200px' }}
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="jobDescription" muted>
                    Skup się na pracach, które wykonujesz i odpowiedzialnościach, jakie masz. Jeżeli zarządzasz zespołem, opisz jak duży jest i czym się zajmuje.
                </Form.Text>
            </Col>            
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}><strong>Aspekty obecnej pracy, które cenisz</strong></Form.Label>
            <Col sm={5}>
                <Form.Control
                as="textarea"
                name="valuedAspects"
                value={formData.valuedAspects}
                onChange={handleChange}
                disabled={isSubmitted}
                style={{ height: '200px' }}
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="valuedAspects" muted>
                    Te informacje pomogą wybrać ścieżkę rozwoju, uwzględniając Twoje preferencje.
                </Form.Text>
            </Col>

        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}><strong>Aspekty obecnej pracy, które frustrują</strong></Form.Label>
            <Col sm={5}>
                <Form.Control
                as="textarea"
                name="frustratingAspects"
                value={formData.frustratingAspects}
                onChange={handleChange}
                disabled={isSubmitted}
                style={{ height: '200px' }}
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="frustratingAspects" muted>
                    Te informacje pomogą wybrać ścieżkę rozwoju, unikając elementów, które Cię frustrują.
                </Form.Text>
            </Col>

        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Preferowany typ pracy</Form.Label>
            <Col sm={5}>
                <Form.Control
                type="text"
                name="workTypePreference"
                value={formData.workTypePreference}
                onChange={handleChange}
                disabled={isSubmitted}
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="frustratingAspects" muted>
                    Interesuje Cię praca przy komputerze, praca w terenie, a może jeszcze inny tryb pracy lub ich kombinacja?
                </Form.Text>
            </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Procent czasu poza domem rocznie</Form.Label>
            <Col sm={5}>
                <Form.Control
                type="text"
                name="travelPercentage"
                value={formData.travelPercentage}
                onChange={handleChange}
                disabled={isSubmitted}
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Ograniczenia do pracy na jakimś stanowisku</Form.Label>
            <Col sm={5}>
                <Form.Control
                type="text"
                name="jobLimitations"
                value={formData.jobLimitations}
                onChange={handleChange}
                disabled={isSubmitted}
                />
            </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-1">
            <Form.Label column sm={2}>Wymarzona praca</Form.Label>
            <Col sm={5}>
                <Form.Control
                as="textarea"
                name="dreamJob"
                value={formData.dreamJob}
                onChange={handleChange}
                disabled={isSubmitted}
                style={{ height: '200px' }}
                />
            </Col>
            <Col sm={5}>
                <Form.Text id="dreamJob" muted>
                    Opisz swoją wymarzoną pracę - jakie elementy powinny się w niej znaleźć, a jakie zdecydowanie nie. Szczególnie unikaj nazywania stanowisk, a czynności uogólniaj - np. <em>musi być praca zespołowa i jak najmniej pracy powtarzalnej</em>. Dzięki temu łatwiej będzie wybrać stanowiska, które będą dla Ciebie bardziej odpowiednie.
                </Form.Text>
            </Col>
        </Form.Group>

        <br /><br /><br />

        <Navbar expand="lg" fixed="bottom" bg="dark" data-bs-theme="dark">
            <Container>
                <Col sm="3">
                    <Button variant="success" onClick={handleSave} disabled={isSaved || isSubmitted}>{isSaved ? 'Zapisane...' : 'Zapisz aktualny stan' }</Button>
                    {isSubmitted && <Alert variant="success">Dziękuję za przesłanie danych. Już pracuję nad Twoim raportem. Spodziewaj się go w ciągu 24h. Pozdrawiam, Anna</Alert>}
                </Col>
                <Col sm="6" className='text-center'>
                    <ProgressBar striped variant="warning" now={progress} />
                </Col>
                <Col sm="3" className='text-right'>
                    <Button variant="primary" type="submit" disabled={isSubmitted}>Przygotuj raport</Button>
                    &nbsp;
                </Col>
            </Container>
        </Navbar>

        </Form>
    </>
  );
};

export default UserForm;
