import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';

const Signup: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [consent, setConsent] = useState<boolean>(false);
  const [isSubscriber, setIsSubscriber] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<{
    email?: string;
    password?: string;
    repeatPassword?: string;
    consent?: string;
  }>({});

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: typeof validationErrors = {};

    if (!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
      errors.email = 'Podaj prawidłowy adres email';
    }

    if (!password) {
      errors.password = 'Hasło jest wymagane';
    }

    if (password !== repeatPassword) {
      errors.repeatPassword = 'Hasła muszą być takie same';
    }

    if (!consent) {
      errors.consent = 'Zgoda jest wymagana';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      const response = await axios.post('https://trener.zarabiamboumiem.pl:8000/register', {
        username: email,
        password: password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        navigate('/report');
        window.location.reload()      
      }
    } catch (error) {
      // Obsługa błędów z API (jeśli potrzebna)
      console.error('Błąd rejestracji:', error);
    }
  };

  const checkSubscriber = async () => {
    const errors: typeof validationErrors = {};
    try {
      const response = await axios.post('https://trener.zarabiamboumiem.pl:8000/check-subscriber', {
        email: email,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        errors.email = '';
        setValidationErrors(errors);
        setIsSubscriber(true)
      }
    } catch (error) {
      setIsSubscriber(false)
      errors.email = 'Podany email nie znajduje się w bazie subskrybentów bloga zarabiamboumiem.pl';
      setValidationErrors(errors);
      return;
    }
  };


  return (
    <>
      {validationErrors.consent && <Alert variant="danger" className='mt-5'>{validationErrors.consent}</Alert>}

      <div className="signup-container">

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => checkSubscriber()}
              isInvalid={!!validationErrors.email}
              isValid={isSubscriber}
            />
            <Form.Control.Feedback type="invalid">{validationErrors.email}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password" className='mt-2'>
            <Form.Label>Hasło</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={!!validationErrors.password}
            />
            <Form.Control.Feedback type="invalid">{validationErrors.password}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="repeatPassword" className='mt-2'>
            <Form.Label>Powtórz hasło</Form.Label>
            <Form.Control
              type="password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              isInvalid={!!validationErrors.repeatPassword}
            />
            <Form.Control.Feedback type="invalid">{validationErrors.repeatPassword}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="consent" className='mt-2'>
            <Form.Check
              type="checkbox"
              label={
                <span>
                    Potwierdzam, że zapoznałem się z <a href='/regulamin' target="_blank" rel="noopener noreferrer">Regulaminem</a> i <a href='/polityka-prywatnosci' target="_blank" rel="noopener noreferrer">Polityką Prywatności</a> oraz akceptuję ich warunki.
                </span>
              }
              checked={consent}
              onChange={(e) => setConsent(e.target.checked)}
              isInvalid={!!validationErrors.consent}
            />
          </Form.Group>

          <div className='mt-3'>
            <Button variant="primary" type="submit">
              Stwórz konto
            </Button>
            &nbsp;&nbsp;
            Jeżeli masz już konto, przejdź do <a href="/login">strony logowania</a>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Signup;

