import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Logout() {

    const navigate = useNavigate();

    localStorage.removeItem('jwt_token');

    useEffect(() => {
        console.log('in Logout before navigate')
        navigate('/', { replace: true });    
        console.log('in Logout after navigate')
        window.location.reload()       
    });

    return (
        <>
            <div className='mt-5'>Trwa wylogowywanie...</div>
        </>
    )
}
